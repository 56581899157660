//Прижатый футер
@mixin stickyFooter() {
  .main-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex-grow: 1;
  }
}

@mixin center-container($brp: $brp-1170, $gutter: 15px) {
  max-width: $brp;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter;
  padding-right: $gutter;

  //Добавляем размер падинга между колонками + скроллбар
  @include breakpoint($brp + (2*$gutter) + 16px) {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin oneLineText() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//Миксины для выравнивания
@mixin centerXY() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerX() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerY() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

//Данный миксин нужно вызывать для блока с 3 элементами span внутри
@mixin mobileMenu($width:35px, $height:25px, $bg:#fff) {
  cursor: pointer;
  width: $width;
  height: $height;

  span {
    position: relative;
    width: 100%;
    height: 2px;
    background: $bg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all $transition-style-medium;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  //При открытом меню
  &.js-active {
    span {
      background: #fff;

      &:nth-child(2) {
        background: transparent;
      }

      &:nth-child(1) {
        transform: rotate(45deg) translate(8px, 9px);
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(8px, -9px);
      }
    }
  }
}

@mixin preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 100500;

  .preloader-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}


@mixin section-title {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  font-size: 3rem;
  font-weight: 700;
}


// $min - минимальный размер шрифта
// $max - максимальный размер шрифта
// $brp1 - начальный брейкпоинт
// $brp2 - конечный брейкпоинт
@mixin fluidFontSize($min, $max, $brp1, $brp2) {
  font-size: $min;

  @include breakpoint($brp1) {
    $k : ($max - $min) / ($brp2 - $brp1);
    $b : $min - ($k * $brp1);
    $temp : $k * 100vw;
    font-size: calc(#{$temp} + #{$b});
  }

  @include breakpoint($brp2) {
    font-size: $max;
  }
}

