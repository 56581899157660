//Шапка одинаковых секций
.section-header{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @include breakpoint($brp-768){
    flex-direction: row;
    align-items: center;
  }
}
.section-header__title{
  font-size: 4.2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.section__header-link{
  display: inline-block;
  border-bottom: 1px solid;
  color: $main-color;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: border-bottom-color $transition-style-fast;

  &:hover{
    border-bottom-color: transparent;
  }
}

//Новости
.news{
  @include center-container();
  margin-top: 70px;
}
.news__header{
  margin-bottom: 20px;
}
.news__header-title{
  margin-right: 20px;
  margin-bottom: 10px;

  @include breakpoint($brp-768){
    margin-bottom: 0;
  }
}
.news__header-link{

}
.news__list{

}
.news__list-inner{

  @include breakpoint($brp-768) {
    box-sizing: border-box;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
  }
}
.news__item{

  width: 250px;
  height: auto;

  @include breakpoint($brp-480) {
    width: 280px;
  }

  @include breakpoint($brp-768) {
    padding: 15px;
    width: 50%;
  }

  @include breakpoint($brp-1024) {
    width: 33.33%;
  }
}
.news__item-inner{
  overflow: hidden;
  border-radius: 10px 10px 8px 8px;
  border: 1px solid #e0e0e0;
  height: 100%;
}
.news__item-img{
  text-align: center;

  img{
    max-height: 260px;
  }
}
.news__item-info{
  padding: 30px 15px;

  @include breakpoint($brp-480){
    padding-left: 25px;
    padding-right: 25px;
  }
}
.news__item-date{
  margin-bottom: 10px;
  color: #bdbdbd;
  font-size: 16px;
}
.news__item-title{
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}
.news__item-desc{
  line-height: 1.5;
  font-weight: 300;
}

//Пресса о нас
.press{
  @include center-container();
  margin-top: 70px;
}
.press__header{
  margin-bottom: 20px;
}
.press__header-title{
  margin-right: 20px;
  margin-bottom: 10px;

  @include breakpoint($brp-768){
    margin-bottom: 0;
  }
}
.press__header-link{

}
.press__list{

}
.press__list-inner{

  @include breakpoint($brp-768) {
    box-sizing: border-box;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
  }
}
.press__item{

  width: 250px;
  height: auto;

  @include breakpoint($brp-480) {
    width: 280px;
  }

  @include breakpoint($brp-768) {
    padding: 15px;
    width: 50%;
  }

  @include breakpoint($brp-1024) {
    width: 33.33%;
  }
}
.press__item-inner{
  overflow: hidden;
  border-radius: 10px 10px 8px 8px;
  border: 1px solid #e0e0e0;
  height: 100%;
}
.press__item-img{
  text-align: center;

  img{
    max-height: 260px;
  }
}
.press__item-info{
  padding: 30px 15px;

  @include breakpoint($brp-480){
    padding-left: 25px;
    padding-right: 25px;
  }
}
.press__item-date{
  margin-bottom: 10px;
  color: #bdbdbd;
  font-size: 16px;
}
.press__item-title{
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}
.press__item-desc{
  line-height: 1.5;
  font-weight: 300;
}

//Отзывы
.responses{
  @include center-container();
  margin-top: 70px;
  margin-bottom: 20px;
}
.responses__header{
  margin-bottom: 20px;
}
.responses__header-title{
  margin-right: 20px;
  margin-bottom: 10px;

  @include breakpoint($brp-768){
    margin-bottom: 0;
  }
}

.responses__list-inner{

  @include breakpoint($brp-768) {
    box-sizing: border-box;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
  }
}
.responses__item{

  width: 250px;
  height: auto;

  @include breakpoint($brp-480) {
    width: 320px;
  }

  @include breakpoint($brp-768) {
    padding: 15px;
    width: 50%;
  }

}
.responses__item-inner{
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
  background-color: #fafafa;

  @include breakpoint($brp-1024){
    flex-direction: row;
    align-items: flex-start;
    padding: 25px;
  }
}
.responses__item-img{
  margin-bottom: 10px;

  @include breakpoint($brp-1024){
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 20px;
    width: 127px;
  }
}
.responses__item-info{

}
.responses__item-title{
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}
.responses__item-desc{
  font-weight: 300;
  line-height: 1.5;
}


.external-scripts{
  margin-top: 50px;
  margin-bottom: 50px;
}

.external-scripts__inner{
  @include center-container();
  display: flex;
  flex-direction: column;

  @include breakpoint($brp-1170) {
    flex-direction: row;
    justify-content: space-between;
    max-height: 560px;
  }
}

.external-scripts__title{
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}
.external-scripts__subtitle{
  margin-bottom: 30px;
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: uppercase;
}

.external-scripts__apteka-online{
  margin-bottom: 40px;

  @include breakpoint($brp-1170) {
    margin-right: 5%;
    margin-bottom: 0;
    width: 47.5%;
  }
}
.external-scripts__apteka-online-body{
  li{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
  }
  img{
    flex-shrink: 0;
    float: none !important;
    margin-right: 15px;
    width: 100px !important;
  }

  a{
    border-bottom: 1px solid transparent;
    max-width: 420px;
    color: $main-color;
    transition: border-bottom-color $transition-style-fast;

    &:hover{
      border-bottom-color: $main-color;
    }
  }
}

.external-scripts__marion{
  @include breakpoint($brp-1170) {
    width: 47.5%;
  }
}
.external-scripts__marion-body{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;

  @include breakpoint($brp-1170) {
    max-height: 86%;
  }

  p{
    margin-bottom: 20px;
    font-size: 16px;

    @include breakpoint($brp-1170) {
      width: 45%;
    }
  }

  a{
    color: $main-color;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color $transition-style-fast;

    &:hover{
      border-bottom-color: $main-color;
    }
  }
}