.post-pagination {
  margin-top: 30px;
  text-align: center;
  font-weight: 300;

  a, span {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    transition: border-color $transition-style-fast,
    background-color $transition-style-fast,
    color $transition-style-fast;
  }

  span.current,
  a:hover {

    color: #ffffff;
    border-color: $main-color;
    background: $main-color;
  }

  a {
    border-color: #424242;
  }

  .page-numbers{
    &:not(:first-child){
      margin-left: 10px;
    }
  }

  .next,
  .prev{
    display: none;
  }
}