.main-footer{
  border-top: 1px solid #e0e0e0;
  padding-top: 30px;
  padding-bottom: 20px;
  color: $text-color1;
}
.main-footer__inner{
  @include center-container();
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include breakpoint($brp-1024){
    flex-wrap: nowrap;
  }

}

//Меню
.main-menu-footer{
  max-width: 335px;
  width: 100%;
}
.main-menu-footer__list{
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @include breakpoint($brp-480){
    flex-direction: column;
    max-height: 120px;
  }

}
.main-menu-footer__item{
  line-height: 2;

  &:not(:last-child){
    margin-right: 15px;
  }

  @include breakpoint($brp-480) {
    line-height: 1.7;

    &:not(:last-child){
      margin-right: 0;
    }

    &:nth-child(n+6) {
      margin-left: 40px;
    }
  }

  //&.current-menu-item a,
  a:hover{
    border-bottom-color: #bfc6c6;
  }

  a{
    border-bottom: 1px solid transparent;
    transition: border-bottom-color $transition-style-fast;
  }
}

//Сайдбар
.footer-sidebar{
  margin-top: 30px;
  width: 100%;

  @include breakpoint($brp-640){
    margin-top: 0;
    width: auto;
  }

  @include breakpoint($brp-1024){
    @include centerX();
  }
}
.contacts_footer{
  font-weight: 300;
  @include breakpoint($brp-640){
    text-align: right;
  }

  @include breakpoint($brp-1024){
    text-align: center;
  }

  .contacts__title{
    margin-bottom: 5px;
  }

  .contacts__phones{
    font-size: 2.4rem;
  }

  .contacts__email{
    color: #0288d1;
    font-size: 16px;
    text-decoration: underline;
  }
}

.copy-and-socials{
  margin-top: 30px;
  width: 100%;

  @include breakpoint($brp-1024){
    margin-top: 0;
    width: auto;
  }
}

//Копирайт
.copy{

  line-height: 1.7;

  @include breakpoint($brp-640){
    text-align: center;
  }

  @include breakpoint($brp-1024){
    max-width: 340px;
    text-align: right;
  }

  a{
    text-decoration: underline;
  }
}

//Социальные сети
.socials-menu{
  margin-top: 15px;
}
.socials-menu__list{
  display: flex;

  @include breakpoint($brp-640){
    justify-content: center;
  }

  @include breakpoint($brp-1024){
    justify-content: flex-end;
  }

  .menu-item{
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    width: 38px;
    height: 38px;

    &:not(:first-child){
      margin-left: 5px;
    }

    &.vk{
      svg{
        width: 22px;
      }
    }

    &.fb{
      svg{
        width: 18px;
      }
    }
  }

  a{
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 0;

    svg{
      display: block;
      margin: auto;
      height: auto;

      path{
        fill: #757575;
      }
    }
  }

}
